import Hero from "components/Landing/Hero";
import Course from "components/Landing/Course";

export default function Landing() {
  return (
    <div className="landing-page-container">
      <Hero />
      <Course />
    </div>
  );
}
